export enum Page {
  Login = 'login',
  ForgotPassword = 'forgot-password',
  CreateUser = 'create-user',
  UserManagement = 'user-management',
  CustomerLookup = 'customer-lookup',
  DiscountsPage = 'discounts',
  ManageDataPage = 'manage-data',
  ItemsPage = 'items',
  ProductItemsPage = 'product-items',
  PromotionsPage = 'promotions',
  DiscountLookup = 'discount-lookup',
}

export type Group = {
  id: string;
  name: string;
};

export enum UserGroup {
  Admin = 'BarkAdmin',
  TeamMember = 'BarkTeamMember',
  OpsAdmin = 'BarkOpsAdmin',
  AllModuleAccess = 'BarkAllModuleAccess',
}

export type BarkUser = {
  first_name: string;
  last_name: string;
  email: string;
  groups: Group[];
  is_active: string;
  created: string;
  last_updated: string;
};

export enum PetStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  CANCELLED = 'CANCELLED',
  DECEASED = 'DECEASED',
  DECEASED_TO_NOT_LIST = 'DECEASED_TO_NOT_LIST',
  UNKNOWN = 'UNKNOWN',
}

export enum PetPlanStatus {
  ACTIVE = 'ACTIVE',
  HOLD = 'HOLD',
  CANCELLED = 'CANCELLED',
  PAUSED = 'PAUSED',
  INITIALIZING = 'INITIALIZING',
}

export type Pet = {
  id: string;
  name: string;
  status: PetStatus;
  gender: string;
  birth_month?: string;
  birth_year?: string;
  breeds?: string[];
  properties?: any;
  pet_plan?: PetPlan;
  account?: string;
};
export type PetPlan = {
  account: string;
  id: string;
  calories_per_day: number;
  discount_amt: number;
  discounted_total_price: number;
  frequency: number;
  is_topper: boolean;
  last_updated: string;
  next_charged_date: string;
  pet: string;
  plan_size: number;
  products: PetPlanProduct[];
  recipe_data: Recipe[];
  recipes: string[];
  start_date: string;
  status: string;
  status_date: string;
  total_price: number;
};

export type PetPlanProduct = {
  id: string;
  code: string;
  created: string;
  frequency: number;
  name: string;
  next_charge_date: string;
  price_per_week: string;
  product_id: string;
  product_type: string;
  quantity: number;
  recipes: Recipe[];
  status: string;
  type: string;
  unit_price: string;
};

export type FulfillmentProduct = {
  id: string;
  pet_id: string;
  pet_plan_id: string;
  plan_size: PlanSize;
  code: string;
  name: string;
  discount_amount: number;
  historical_product_sku: string;
  is_frozen: boolean;
  items: Item[];
  price: number;
  quantity: number;
  tax_amount: number;
  type: ProductType;
};

export type PlanSize = { id: number; scoop_calories: number };

export type Order = {
  id: string;
  account: string;
  products: OrderProduct[];
  total_price: string | null;
  total_refunds: string;
  discounts: OrderDiscount[];
  charged: string | null;
  status: OrderStatus;
  order_type: OrderType;
  pricing_model: PricingModel | null;
  fulfillments: Fulfillment[];
};

export type OrderProductPetPlan = {
  id: string;
  pet_id: string;
  pet_name: string;
  plan_size_code: string;
  recipes: string[];
  start_date: string;
  status: PetPlanStatus;
};

export type OrderProduct = {
  id: string;
  code: string;
  name: string;
  pet_plan?: OrderProductPetPlan; // Note: pet_plan may not exist on older migrated orders.
  product: string;
  product_type: ProductType;
  quantity: number;
  recipes: Recipe[];
  type: OrderProductType;
  unit_price: string;
  tax_amount: string;
  discount_amount: string;
  refund_amount: string | null;
  has_promotion: boolean;
  price: Price;
  image_uri: string | null;
};

export enum OrderStatus {
  PREVIEW = 'PREVIEW',
  PROCESSING = 'PROCESSING',
  CHARGING = 'CHARGING',
  MAX_RETRY_REACHED = 'MAX_RETRY_REACHED',
  READY_FOR_FULFILLMENT = 'READY_FOR_FULFILLMENT',
  FULFILLING = 'FULFILLING',
  PARTIALLY_FULFILLED = 'PARTIALLY_FULFILLED',
  FULFILLED = 'FULFILLED',
  COMPLETE = 'COMPLETE',
  HOLD = 'HOLD',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
  HISTORICAL = 'HISTORICAL',
  RUSH_ORDER = 'RUSH_ORDER',
}

export enum OrderType {
  TRIAL = 'TRIAL',
  SUBSCRIPTION = 'SUBSCRIPTION',
  ONETIME = 'ONETIME',
  REPLACEMENT = 'REPLACEMENT',
}

export enum OrderProductType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  ADDON = 'ADDON',
  ONETIME = 'ONETIME',
}

export type Orders = {
  past: Order[];
  processing: Order[];
  upcoming: Order[];
};

export type Fulfillment = {
  address1: string;
  address2: string | null;
  box_number: number;
  city: string;
  company: string | null;
  country: string;
  created: string;
  first_name: string;
  id: string;
  last_name: string;
  last_updated: string;
  order: string;
  orderdesk_id: string;
  pet: string;
  pet_ids: string[];
  shipments: Shipment[];
  shopify_order_id: null;
  state: string;
  status: FulfillmentStatus;
  zip: string;
  products: FulfillmentProduct[];
  is_split_fulfillment: boolean;
};

export enum FulfillmentStatus {
  SCHEDULED = 'SCHEDULED',
  FULFILLMENTS_CREATED = 'FULFILLMENTS_CREATED',
  PREPARING = 'PREPARING',
  ORDERDESK_SENT = 'ORDERDESK_SENT',
  CREATING_AFTERSHIP_SHIPMENTS = 'CREATING_AFTERSHIP_SHIPMENTS',
  PARTIALLY_SHIPPED = 'PARTIALLY_SHIPPED',
  SHIPPED = 'SHIPPED',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
  DELIVERY_FAILED = 'DELIVERY_FAILED',
}

export type Shipment = {
  aftership_exception_message: string | null;
  aftership_object: AftershipObject;
  aftership_tracking_id: string | null;
  carrier_code: CarrierCode | string | null;
  cost_of_shipping: string;
  created: string;
  delivered: string | null;
  exception: string | null;
  fulfillment: string;
  id: string;
  in_transit: string | null;
  last_updated: string;
  orderdesk_id: string | null;
  out_for_delivery: string | null;
  available_for_pickup: string | null;
  returned: string | null;
  shipped: string | null;
  shipping_class: string | null;
  tracking_number: string | null;
  voided: string | null;
};

type AftershipObject = {
  id: string;
  last_updated_at: string;
  tag: string;
};

export enum CarrierCode {
  FEDEX = 'fedex',
  UPS = 'ups',
}
export enum ProductType {
  RECIPE = 'RECIPE',
  MEAL = 'MEAL',
  SCOOP = 'SCOOP',
  SNACK = 'SNACK',
  MATERIAL = 'MATERIAL',
  REPLACE = 'REPLACE',
  SAMPLE = 'SAMPLE',
  SUPPLEMENT = 'SUPPLEMENT',
  MERCH = 'MERCH',
}

export type ProductCodesResponse = {
  id: string;
  code: string;
  type: ProductType;
};

export type Customer = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  pets: Pet[];
  created: string;
  last_updated: string;
  phone_number: string;
  orders: Orders;
  status?: string;
  petplans: any[]; // TODO: Define this as a Bark Type
  survey_responses: any[]; // TODO: Define this as a Bark Type
  default_pricing_model: PricingModel | null;
};

export type Address = {
  id: string;
  account: string;
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  zip: string;
  country: string;
  latitude: string | null;
  longitude: string | null;
  is_primary: boolean;
  type: 'SHIPPING' | 'BILLING';
  shipping_name: string | null;
};

export type Option = {
  value: string;
  label: string;
  hasBadge?: boolean; //Can be used if need to show badge
};

export type ProductCodes = {
  id: string;
  code: string;
  type: string;
};

export enum ChangelogOperation {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export type CustomerChangelogItem = {
  id: string;
  application: string | null;
  accountId: string;
  table: string;
  fieldName: string;
  newValue: string;
  petId: string | null;
  operationType: ChangelogOperation;
  authorEmail: string;
  rowPrimaryKey: string;
  createdAt: string;
};

export type SystemChangelogItem = {
  category: string;
  objectId: string;
  application: string | null;
  createdAt: string;
  fieldName: string;
  newValue: string;
  operationType: ChangelogOperation;
  authorEmail: string;
};

export type ChangelogItem = CustomerChangelogItem | SystemChangelogItem;

export type OrderDiscount = {
  id: string;
  discount: Discount;
  amount: string;
  created: string;
  order: string;
  status: string;
};

export type Discount = {
  value: string;
  order_onetime: boolean;
  order_trial: boolean;
  order_subscription: boolean;
  name: string;
  limit_type: string;
  limit_value: string;
  discount_type: string;
  available_end_date: string;
  available_start_date: string;
  code: string;
  id: string;
  is_active: boolean;
  created: string;
};

export enum DiscountExclusiveType {
  WINBACK_10_PERCENT = 'WINBACK_10_PERCENT',
  WINBACK_30_PERCENT = 'WINBACK_30_PERCENT',
}

export type Treatment = {
  feature_flag: string;
  feature_flag_status: string;
  treatment_distribution: number;
  treatment_control: boolean;
  treatment_name: string;
  created: string;
};

export type Item = {
  id: string;
  sku: string;
  name: string;
  quantity?: number;
  recipe: string;
  weight_oz: number;
  volume: number;
};
export type Recipe = {
  id: string;
  description: string;
  name: string;
  seq_no: number;
  aafco_statement: string;
  type: string;
};

export type ProductRelatedItem = {
  sku: string;
  quantity: number;
  id?: string; // This is only used in the UI, unique key for the list
};

export type ProductItem = {
  id: string;
  code: string;
  name: string;
  status: string;
  items: ProductRelatedItem[];
};

export type PricingModel = {
  created: string;
  creator: string | null;
  description?: string;
  id: string;
  is_default: boolean;
  last_updated: string;
  name: string;
  status: string;
};

export enum PromotionType {
  ORDER = 'ORDER',
  PET = 'PET',
}

export enum PromotionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum PromotionBannerTypes {
  MARKETING_SITE = 'MARKETING_SITE',
  ALL_FUNNEL_PAGES = 'ALL_FUNNEL_PAGES',
  ONLY_CHECKOUT_PAGE = 'ONLY_CHECKOUT_PAGE',
  NO_FUNNEL_UI = 'NO_FUNNEL_UI',
}

export type Promotion = {
  id: string;
  name: string;
  promotion_type: PromotionType;
  banner_type: PromotionBannerTypes;
  banner_copy: string;
  created_by: string;
  free_product_code: string;
  quantity: number;
  minimum_order_gross_value_threshold_amount: number;
  start_timestamp: string;
  end_timestamp: string;
  status: PromotionStatus;
  required_conditions_products: string[];
  created: string;
  last_updated: string;
};

export type Price = {
  id: string;
  price: string;
  price_per_meal: string | null;
  price_per_day: string | null;
  price_per_week: string | null;
  discounted_price_per_pack: string | null;
  discounted_price_per_meal: string | null;
  discounted_price_per_day: string | null;
  discounted_price_per_week: string | null;
  discounted_price: string | null;
  pricing_model: PricingModel;
  created: string;
  last_updated: string;
  frequency: number;
};

export enum GetProductsRequestType {
  ONE_TIME_ORDER = 'one-time-order',
  ONE_TIME_PRODUCT = 'one-time-product',
  ONE_TIME_ORDER_PRODUCTS = 'one-time-order-non-recurring',
}
