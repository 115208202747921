import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from './Button';
import { ENABLE_CLAIMS_FEATURE } from '../api';

interface ClaimsColumnProps {
  claimId: string | null;
  onDeleteClick: (claimId: string) => void;
  userHasDeleteAccess?: boolean;
}

export const ClaimsColumn = ({
  claimId,
  onDeleteClick,
  userHasDeleteAccess = false,
}: ClaimsColumnProps) => {
  const navigate = useNavigate();

  if (!ENABLE_CLAIMS_FEATURE) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2 justify-center">
      {claimId ? (
        <>
          <Button
            label="Edit Claim"
            onClick={() => navigate(`/claim/${claimId}`)}
            className="px-[2px] py-[2px]"
            variant="secondary"
            textClassName="text-sm font-normal"
          />
          {userHasDeleteAccess && (
            <Button
              label="Delete Claim"
              onClick={() => onDeleteClick(claimId)}
              className="px-[2px] py-[2px] text-red-600"
              variant="secondary"
              textClassName="text-sm font-normal text-red-600"
            />
          )}
        </>
      ) : (
        <Button
          label="Create Claim"
          onClick={() => navigate('/claim/create-claim')}
          className="px-[2px] py-[2px]"
          variant="secondary"
          textClassName="text-sm font-normal"
        />
      )}
    </div>
  );
};
