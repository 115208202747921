import * as React from 'react';
import { useMemo, useState } from 'react';

import { OneTimeProductStep1 } from './OneTimeProductStep1';
import { OneTimeProductStep2 } from './OneTimeProductStep2';
import { Close } from '../../assets/Close';
import { mapSntApiDate } from '../../dateUtils';
import { formatingOptions } from '../../formatingOptions';
import { useGetProducts } from '../../hooks/getProducts';
import { generateFullName } from '../../nameUtils';
import { GetProductsRequestType, Option } from '../../pages/types';
import { LineInfo } from '../LineInfo';

export const OneTimeProductContext = React.createContext<any>(null);

export const OneTimeProductModal = ({ customer, order, onConfirm, onCancel }: any) => {
  const { pets } = customer;
  const orderPets = useMemo(
    () => order.products.map((product: any) => product.pet_plan.pet_name),
    [order.products]
  );
  const [selectedDog, setSelectedDog] = useState<Option>();

  const [selectedProducts, setSelectedProducts] = useState<Option[]>([]);

  const { products, isLoading } = useGetProducts(GetProductsRequestType.ONE_TIME_PRODUCT);

  const [currentStep, setCurrentStep] = useState(1);

  const dogs = formatingOptions(
    pets,
    { value: 'id', label: 'name' },
    {
      property: 'name',
      operator: 'enters',
      value: JSON.stringify(orderPets),
    }
  );

  const isEverythingSelected = useMemo(
    () => !!selectedDog?.label && selectedProducts.length > 0,
    [selectedDog?.label, selectedProducts.length]
  );

  const navigateBetweenSteps = () => setCurrentStep(isEverythingSelected ? 2 : 1);

  const context = {
    customer,
    order,
    dogs,
    fullProducts: products,
    selectedDog,
    setSelectedDog,
    selectedProducts,
    setSelectedProducts,
    isEverythingSelected,
    isLoading,
    navigateBetweenSteps,
    onConfirm,
    scheduledDate: mapSntApiDate(order.scheduled),
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 modal">
      <div className="bg-white rounded-lg shadow-md p-4 min-w-[700px] relative border">
        <Close className="absolute top-1 right-1 w-8 h-8 cursor-pointer" onClick={onCancel} />
        <h1 className="font-bold text-left mb-2">Add One-Time Product</h1>
        <LineInfo
          title="Customer"
          value={`${generateFullName(customer.first_name, customer.last_name)} (${customer.email})`}
        />
        <LineInfo title="Order Date" value={mapSntApiDate(order.scheduled)} />
        <hr />
        <OneTimeProductContext.Provider value={context}>
          {currentStep === 1 ? <OneTimeProductStep1 /> : <OneTimeProductStep2 />}
        </OneTimeProductContext.Provider>
      </div>
    </div>
  );
};
