import React from 'react';

import { calculateUnitPriceAfterDiscount } from '../financialUtils';

interface ProductPriceProps {
  product: {
    quantity: number;
    unit_price: string;
    discount_amount: string;
  };
}

const ProductPrice = ({ product }: ProductPriceProps) => {
  return (
    <>
      {product.discount_amount === '0.00' ? (
        `${product.quantity} X $${product.unit_price}`
      ) : (
        <div>
          {`${product.quantity} X $`}
          <span className="line-through">{product.unit_price}</span> $
          {calculateUnitPriceAfterDiscount(product)}
        </div>
      )}
    </>
  );
};

export default ProductPrice;
