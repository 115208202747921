import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { axios } from '../api';
import { GetProductsRequestType, ProductCodesResponse } from '../pages/types';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

export const useGetProducts = (
  requestType: GetProductsRequestType = GetProductsRequestType.ONE_TIME_ORDER
) => {
  const [products, setProducts] = useState<ProductCodesResponse[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const oneTimeOrderEndpointPart = '/products/codes';
  const oneTimeOrderProductsEndpointPart = '/products/one-time-order-products';
  const oneTimeProductEndpointPart = '/products/one-time-codes';

  let apiEndpoint = oneTimeOrderEndpointPart;
  switch (requestType) {
    case GetProductsRequestType.ONE_TIME_PRODUCT:
      apiEndpoint = oneTimeProductEndpointPart;
      break;
    case GetProductsRequestType.ONE_TIME_ORDER_PRODUCTS:
      apiEndpoint = oneTimeOrderProductsEndpointPart;
      break;
  }

  const getProducts = () => {
    axios
      .get(apiEndpoint)
      .then((response) => {
        setProducts(response.data);
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { products, isLoading };
};
