import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { ItemSelector } from './ItemSelector';
import { Option, ProductRelatedItem } from './types';
import { axios } from '../api';
import { Button } from '../component/Button';
import { ConfirmationModal } from '../component/ConfirmationModal';
import { SearchableDropdown } from '../component/SearchableDropdown';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';
import { generateUniqueId } from '../utils';

const Step1 = ({
  selectedProductId,
  setSelectedProductId,
  setCurrentStep,
}: {
  selectedProductId: string | undefined;
  setSelectedProductId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [productOptions, setProductOptions] = useState<Option[]>([]);
  const requiredFieldsAreValid = selectedProductId !== undefined;
  const selectedProductCode = productOptions.find(
    (option) => option.value === selectedProductId
  )?.label;

  useEffect(() => {
    axios
      .get(`products/codes`)
      .then((response) => {
        setProductOptions(
          response.data.map((product: { id: string; code: string }) => ({
            value: product.id,
            label: product.code,
          }))
        );
      })
      .catch((error) => console.error('Error:', error));
  }, []);

  return (
    <>
      <div className="bg-white mt-4 mb-4 rounded-lg shadow-md p-4 min-w-[550px] relative border padd">
        <div className="w-full items-center mb-2">
          <h2>Select Product</h2>
          <SearchableDropdown
            options={productOptions}
            value={
              selectedProductId ? { value: selectedProductId, label: selectedProductCode } : null
            }
            onChange={(newSelection: any) => {
              if (newSelection) {
                setSelectedProductId(newSelection.value);
              }
            }}
          />
        </div>
      </div>
      <div className="flex justify-center flex-col items-center mt-2">
        <Button
          variant={requiredFieldsAreValid ? 'primary' : 'secondary'}
          isDisabled={!requiredFieldsAreValid}
          label="Continue"
          onClick={() => setCurrentStep(2)}
        />
      </div>
    </>
  );
};

const Step2 = ({
  productId,
  selectedItems,
  setSelectedItems,
  action,
}: {
  selectedItems: ProductRelatedItem[];
  setSelectedItems: React.Dispatch<React.SetStateAction<ProductRelatedItem[]>>;
  productId: string | undefined;
  action: string;
}) => {
  const [itemSkus, setItemSkus] = useState<string[]>([]);
  const [showConfirmSaveModal, setShowConfirmSaveModal] = useState(false);
  const navigate = useNavigate();
  const requiredFieldsAreValid =
    selectedItems.length > 0 && selectedItems.every((item) => item.sku !== '' && item.quantity > 0);
  const [productCode, setProductCode] = useState<string>('');

  useEffect(() => {
    axios
      .get(`items/skus`)
      .then((response) => {
        setItemSkus(response.data.sort());
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.error(generateErrorMsg(error), BOTTOM_TOAST);
      });

    axios
      .get(`product_items/${productId}`)
      .then((response) => {
        setProductCode(response.data.code);
        setSelectedItems(
          response.data.items.map((item: ProductRelatedItem) => ({
            ...item,
            id: generateUniqueId(), // Generate unique id for each item (for react list key)
          }))
        );
      })
      .catch((error) => {
        console.error('Error:', error);
        if (error.response && error.response.status === 404) {
          throw new Error(`No product with id ${productId} exists`);
        }
        toast.error(generateErrorMsg(error), BOTTOM_TOAST);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateMapping = () => {
    const requestBody = {
      items: selectedItems.map((item) => ({ sku: item.sku, quantity: item.quantity })),
    };
    axios
      .post(`product_items/${productId}/add_items`, requestBody)
      .then(() => {
        navigate('/product-items');
        toast.success(
          `Successfully ${
            action === 'create' ? 'created' : 'updated'
          } product item mapping for product code ${productCode} `,
          BOTTOM_TOAST
        );
      })
      .catch((e) => {
        setShowConfirmSaveModal(false);
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  return (
    <>
      <div className="bg-white mt-4 mb-4 rounded-lg shadow-md p-4 min-w-[550px] relative border padd">
        <h2 className="pb-2">
          Product: <span className="font-normal">{productCode}</span>
        </h2>
        <div className="w-full border-b border-gray-200 shadow-md mb-4" />
        Items:
        <ItemSelector
          items={itemSkus}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      </div>
      <div className="flex justify-center flex-col items-center mt-2">
        <Button
          variant={requiredFieldsAreValid ? 'primary' : 'secondary'}
          label="Save"
          isDisabled={!requiredFieldsAreValid}
          onClick={() => setShowConfirmSaveModal(true)}
        />
      </div>

      {showConfirmSaveModal && (
        <ConfirmationModal
          title={`Are you sure you want to proceed with ${
            action === 'create' ? 'creating this new' : 'updating this'
          } product-item mapping?`}
          confirmLabel="Confirm"
          onConfirm={() => handleCreateMapping()}
          onCancel={() => setShowConfirmSaveModal(false)}
        />
      )}
    </>
  );
};

const ProductItemsMappingForm = () => {
  const { productId } = useParams<{ productId: string }>();
  const action = productId ? 'update' : 'create';
  const [currentStep, setCurrentStep] = useState(productId ? 2 : 1);
  const [selectedProductId, setSelectedProductId] = useState<string | undefined>(productId);
  const [selectedItems, setSelectedItems] = useState<ProductRelatedItem[]>([]);

  return (
    <div className="flex flex-col justify-center items-center p-8 font-semibold">
      <h1 className="text-left">
        {action === 'create' ? 'Create new' : 'Update'} product-item mapping
      </h1>
      <div className="text-md text-left">
        <div className="w-full mb-2">
          {currentStep === 1 ? (
            <Step1
              selectedProductId={selectedProductId}
              setSelectedProductId={setSelectedProductId}
              setCurrentStep={setCurrentStep}
            />
          ) : (
            <Step2
              productId={selectedProductId}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              action={action}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductItemsMappingForm;
