export const TRACKING_URL = 'https://spotandtango.aftership.com';
const getEnv = () => {
  const url = new URL(window.location.href);
  const subdomain = url.hostname.split('.')[0];

  if (subdomain === 'localhost') {
    return 'LOCAL';
  }
  if (subdomain === 'dev-bark') {
    return 'DEV';
  }
  if (subdomain === 'stage-bark') {
    return 'STAGE';
  }
  if (subdomain === 'bark') {
    return 'PROD';
  }

  throw new Error('Invalid domain name');
};
export const ENV = getEnv();
